import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';
import { keep2Decimal, keep4Decimal } from '@assets/js/regExUtil';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '托收单据号', prop: 'inform_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '原合同供应商',
    prop: 'supp_aabbr',
    itemType: 'input',
    input: true,
    sortable: true,
    widthAuto: true
  },
  { label: '托收供应商', prop: 'supp_babbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '总托收重量', prop: 'total_weight', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '剩余托收总净重', prop: 'remain_weight', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '120px', sortable: false, widthAuto: true },
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    input: true,
    sortable: true,
    labelWidth: '120px',
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    widthAuto: true,
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];

export const informMainFormProperties = [
  {
    label: '原合同供应商',
    prop: 'supp_aabbr',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '合同托收金额',
    prop: 'contract_amount',
    itemType: 'input',
    size: 'small',
    model: '',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true,
    inputAppend: '元'
  },
  {
    label: '原合同金额',
    prop: 'old_contract_amount',
    itemType: 'input',
    size: 'small',
    model: '',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true,
    inputAppend: '元'
  },
  {
    label: '实际托收金额',
    prop: 'actual_amount',
    itemType: 'input',
    size: 'small',
    model: '',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true,
    inputAppend: '元'
  },
  {
    label: '托收供应商',
    prop: 'supp_bid',
    itemType: 'select',
    model: '',
    options: [],
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '总托收重量',
    prop: 'total_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true,
    inputAppend: 'KG'
  },
  {
    label: '实际托收重量',
    prop: 'actual_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 13,
    rules: { required: false, message: ' ' },
    input: (list, index, val) => (list[index].model = keep2Decimal(val)),
    subWidth: 80,
    disabled: false,
    show: true,
    inputAppend: 'KG'
  },
  {
    label: '剩余托收重量',
    prop: 'remain_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true,
    inputAppend: 'KG'
  },
  {
    label: '未托收金额',
    prop: 'not_amount',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true,
    inputAppend: '元'
  },
  {
    label: '费率',
    prop: 'inform_rate',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: '5',
    input: (list, index, val) => (list[index].model = keep2Decimal(val)),
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '公司抬头',
    prop: 'cptt_id',
    itemType: 'select',
    model: '',
    options: [],
    size: 'small',
    filterable: true,
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '托收合同号',
    prop: 'contract_no',
    itemType: 'input',
    model: '',
    size: 'small',
    filterable: true,
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '增值税发票号',
    prop: 'invoice_number',
    itemType: 'input',
    model: '',
    size: 'small',
    filterable: true,
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true,
    maxlength: '25'
  },
  {
    label: '备注',
    prop: 'inform_remarks',
    itemType: 'input',
    type: 'textarea',
    model: '',
    size: 'small',
    filterable: true,
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true,
    maxlength: '200',
    split: 24
  }
];

export const informSubTableProperties = [
  { label: '原材料采购合同号', prop: 'modr_no', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  {
    label: '托收品名',
    prop: 'inform_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: true, disabled: false, maxLength: '8', type: 'input', copyAndPaste: true }
  },
  {
    label: '开票品名',
    prop: 'prod_hsname',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: true, disabled: false, type: 'select', options: [], filterable: true, copyAndPaste: true }
  },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '最终客户', prop: 'cust_babbr', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '原合同金额', prop: 'inform_total', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  {
    label: '托收重量 (KG)',
    prop: 'collection_weight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: true,
      disabled: false,
      type: 'input',
      maxLength: 13,
      input: (val, row) => (row.collection_weight = keep4Decimal(val))
    }
  },
  {
    label: '托收金额 (元)',
    prop: 'collection_amount',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: true, maxLength: '13', type: 'input' }
  },
  {
    label: '实际托收金额',
    prop: 'actual_amount',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      maxLength: '13',
      type: 'input',
      input: (val, row) => (row.actual_amount = keep4Decimal(val))
    }
  },
  {
    label: '托收出运发票号',
    prop: 'tran_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: true, disabled: false, type: 'input', copyAndPaste: true }
  },
  { label: '原报关总额', prop: 'customs_total', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  {
    label: '换汇比例',
    prop: 'swap_point',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: true, type: 'input' }
  }
];
