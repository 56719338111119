<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'inform_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        :need-fixed-height="true"
        @getTableData="getInformsList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { informAPI } from '@api/modules/inform';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/CollectionManagement/InformManage/inform';
import { getArrayIds } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'InformList',
  components: {
    SearchTable
  },
  data() {
    return {
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === '/inform_add' || from.path === '/inform_edit') {
        this.initData();
      }
    }
  },
  mounted() {
    this.getInformsList();
  },
  methods: {
    initData() {
      this.getInformsList();
    },
    async getInformsList() {
      await (this.loadFlag = true);
      await informAPI.getInforms(this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getInformsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/inform_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.inform_id
          })
        )
      });
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/inform_add`, {
        perm_id: permId
      });
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //客户信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          informAPI.deleteInform({ inform_ids: getArrayIds(this.multiSelection, 'inform_id').toString() }).then(({ data }) => {
            this.$message.success('删除成功!');
            this.initData();
          });
        })
        .catch(err => {
          console.error(err);
          this.$message.info('取消删除');
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
